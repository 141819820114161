<template>
  <div class="markdown-tutorial">
    <div class="container">
      <div v-html="tutorial"></div>
    </div>
  </div>
</template>

<script>
import { markdownToHtml } from "@/util/util";

export default {
  data() {
    return {
      tutorial: markdownToHtml(this.$i18n.t("markdownTutorial"))
    };
  }
};
</script>

<style lang="scss" scoped>
.markdown-tutorial {
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: white;
  flex: 1;
}

:deep(pre) {
  background: #f1f1f1;
  padding: 1rem;
  border-radius: 10px;
}

:deep(table) {
  width: 100%;
}
</style>
