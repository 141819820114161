const de = {
  title: "VITU",
  data: {
    loading: "Wird geladen..."
  },
  login: {
    title: "VITU - Virtuelles Tumorboard",
    subtitle: "Melden Sie sich an, um an einer Konferenz teilzunehmen.",
    text: "Melden Sie sich an, um an einer Konferenz teilzunehmen.",
    buttonText: "Anmelden",
    imprintLink: "https://molit.eu/impressum"
  },
  home: {
    home: "Home",
    backToHome: "Zurück zur Hauptseite"
  },
  worklist: {
    organization: "Organisation | Organisationen",
    worklist: "Arbeitsliste | Arbeitslisten",
    caseId: "Fallnummer",
    patient: "Patient | Patienten",
    patientDetails: "Patient Detailansicht",
    existingPatients: "Vorhandene Patienten",
    loadingData: "Lade Daten",
    checkIfPatientExists: "Prüfe ob Patient bereits vorhanden ist",
    creatingPatient: "Erstelle Patient",
    savingData: "Speichere Daten",
    noPatients: "Keine Patienten vorhanden",
    searchPatient: "Patient suchen",
    editPatient: "Patient bearbeiten",
    date: "Datum",
    creationDate: "Erstelldatum",
    birthDate: "Geburtsdatum",
    diagnosis: "Diagnose",
    caseStatus: "Fallstatus",
    entry: "Eintrag | Einträge",
    filterEntries: "Einträge filtern",
    entriesPerPage: "Einträge pro Seite",
    noEntriesFound: "Es konnten keine Fälle gefunden werden.",
    "in-progress": "aktuell",
    completed: "abgeschlossen",
    cancelled: "abgebrochen",
    statusDescription: "Statusbeschreibung",
    cases: "Fälle",
    details: "Detailansicht",
    case: "Fall",
    masterData: "Stammdaten",
    treatmentHistory: "Behandlungshistorie",
    reports: "Befunde",
    approve: "Bestätigen",
    noItemSelected: "Kein Fall ausgewählt",
    currentStatus: "Aktueller Status",
    changeStatus: "Status ändern",
    statusChangedSuccessfully: "Der Status wurde erfolgreich geändert",
    reasonForCancellation: "Grund für Abbruch",
    reasonCode: {
      deceased: "Patient verstorben",
      other: "Sonstiger Grund"
    },
    lastUpdated: "Updatedatum",
    reason: "Grund",
    status: "Status",
    statusCode: {
      WAITING_FOR_APPROVAL: "Warten auf Bestätigung",
      INITIATE_MOLECULAR_DIAGNOSTIC: "Diagnostik einleiten",
      PATIENT_DECLARATION_OF_CONSENT: "Patienteneinverständnis einholen",
      COMMISSION_MOLECULAR_DIAGNOSTIC: "Molekulare Diagnostik beauftragen",
      SEND_SPECIMEN: "Probe verschicken",
      WAIT_FOR_REPORT: "Warten auf Befund",
      DISPATCH_NORMAL_TISSUE: "Normalgewebe verschicken",
      REQUEST_TUMOR_TISSUE: "Tumorgewebe anfordern",
      DISPATCH_TUMOR_TISSUE: "Tumorgewebe verschicken",
      WAIT_FOR_CASE_DISCUSSION: "Warten auf Fallbesprechung",
      ASSIGN_TO_TUMOR_CONFERENCE: "Bereit zur Tumorkonferenz",
      COMPLETE_RECOMMENDATION: "Empfehlungsprotokoll abschließen",
      COMPLETED: "Abgeschlossen",
      CANCELED: "Abgebrochen"
    },
    successfullyAddedCase: "Der Fall wurde erfolgreich zur Arbeitsliste hinzugefügt.",
    successfullyEditedCase: "Der Fall wurde erfolgreich gespeichert.",
    deactivatedStateInfo: `Änderungen der Status im Administrationsbereich können sich auf bereits zugewiesene Status, die nachträglich deaktiviert werden, auswirken.
    Diese werden farblich rot hervorgehoben. Eine Statusänderung des Falls wird empfohlen.`,
    deleteCase: "Fall löschen",
    deleteCaseConfirmation: "Möchten Sie den Fall für den Patienten '{patient}' wirklich löschen?",
    deleteSuccessful: "Der Fall wurde erfolgreich gelöscht.",
    openDataDonation: "Datenspende öffnen",
    hintSearchDate: "Das gesuchte Datum muss in einem der folgenden Muster angegeben werden: JJJJ, JJJJ-MM, JJJJ-MM-TT"
  },
  planner: {
    conferencePlanner: "Planer",
    conferenceModerator: "Fallübersicht",
    schedulableCases: "Terminierbare Fälle",
    planner: "Planer",
    searchCase: "Fall suchen",
    addCase: "Fall hinzufügen",
    searchConference: "Konferenz suchen",
    document: "Dokument",
    casesAssigned: "Fall zugeordnet | Fälle zugeordnet",
    noSchedulableCases: "Keine terminierbaren Fälle",
    scheduleNewConference: "Neue Konferenz planen",
    enterConferenceName: "Konferenznamen eingeben",
    description: "Kurzbeschreibung (optional)",
    time: "Uhrzeit",
    cancel: "Abbrechen",
    create: "Anlegen",
    case: "Fall | Fälle",
    noCases: "Keine Fälle verfügbar",
    selectACase: "Bitte wählen Sie einen Fall aus",
    caseAssignmentSuccessful: "Der Fall wurde der Konferenz erfolgreich zugeordnet",
    caseAlreadyExists: "Fall bereits vorhanden",
    caseAlreadyExistsDescription: "Der Fall wurde bereits zu dieser Tumorkonferenz hinzugefügt.",
    deleteConference: "Konferenz löschen",
    hintConferenceInPast: "Die Uhrzeit für die Konferenz liegt in der Vergangenheit. Sind Sie sicher, dass Sie die Konferenz anlegen möchten?",
    createAppointmentSeries: "Serientermin erstellen",
    cloneConference: "Konferenz klonen",
    comments: "Kommentare",
    patientInformation: "Anmeldedaten",
    results: "Befunde",
    therapyRecommendation: "Therapieempfehlung",
    hintName: "Bitte benennen Sie die Konferenz.",
    hintTime: "Bitte geben Sie eine Uhrzeit an.",
    hintDate: "Bitte geben Sie ein Datum an."
  },
  conference: {
    conference: "Konferenz",
    videoConference: "Videokonferenz",
    shareScreen: "Bildschirm teilen",
    shareAnotherScreen: "Weiteren Bildschirm teilen",
    unshareScreen: "Bildschirmfreigabe beenden",
    endAllScreens: "Bildschirmfreigaben beenden",
    unshareSingleScreen: "Freigabe {screen} beenden",
    shareScreenDescription: "Sie können Ihren Bildschirm mit Klick auf den Button teilen. Sie haben dann die Möglichkeit, den zu teilenden Bildschirm auszuwählen.",
    presentationView: "Präsentationsansicht",
    conferenceView: "Konferenzansicht",
    caseData: "Falldaten",
    searchDocument: "Dokument suchen",
    nextCase: "Nächster Fall",
    presentation: "Präsentation",
    muteAudio: "Stummschalten",
    unmuteAudio: "Stummschalten aufheben",
    muteVideo: "Video ausschalten",
    unmuteVideo: "Video einschalten",
    participants: "Teilnehmer",
    participantsCount: "Teilnehmerzahl",
    participantSpeaking: "Teilnehmer spricht gerade",
    participantNotSpeaking: "Teilnehmer spricht gerade nicht",
    leaveConference: "Konferenz verlassen",
    enterConference: "Konferenz beitreten",
    scheduledConferences: "Geplante Konferenzen",
    otherConferences: "Andere Räume",
    roomNumber: "Nr.",
    initializing: "Raum wird initialisiert",
    tutorial: "Klicken Sie auf den Button um der Konferenz beizutreten. Danach werden Sie aufgefordert, den Zugriff auf Ihre Kamera und das Mikrofon im Browser zu erlauben.",
    noEntries: "Dieser Konferenz wurden keine Fälle zugeordnet.",
    backToOverview: "Zurück zur Übersicht",
    notAllowed: "Sie haben keine Zugriffsberechtigung für diese Konferenz.",
    participantsOfConference: "Teilnehmer der Konferenz",
    participantsWhoTookPart: "Diese Nuzter haben an der Konferenz teilgenommen:",
    participantsDownload: "Teilnehmerliste herunterladen"
  },
  documentation: {
    documentation: "Protokolle",
    existingProtocols: "Vorhandene Protokolle",
    protocol: "Protokoll",
    newProtocol: "Neues Protokoll",
    selectPatient: "Patient auswählen",
    selectPatientInfo: "Bitte wählen Sie einen Patienten über den Button aus.",
    name: "Name",
    noProtocols: "Keine Protokolle vorhanden.",
    informationTumorboard: "Information Tumorboard",
    date: "Datum der Vorstellung",
    participants: "Teilnehmer",
    addParticipant: "Teilnehmer hinzufügen",
    followUp: "Wiedervorstellung empfohlen",
    followUpReason: "Grund für Wiedervorstellung",
    anamnesis: "Anamnese",
    firstDiagnosis: "Erstdiagnose",
    encounterId: "Tumorboard Fall ID",
    therapyRecommendation: "Therapieempfehlung",
    addTherapyRecommendation: "Therapieempfehlung hinzufügen",
    detectedMutations: "Nachgewiesene genetische Aberationen & Bedeutungen",
    selectReport: "Befund auswählen",
    selectReportInfo: "Bitte wählen Sie einen Befund über den Button aus.",
    reportDate: "Befunddatum",
    reportId: "Befund ID",
    laboratory: "Labor",
    recommendedStart: "Empfohlener Beginn",
    therapyRecommendationReason: "Grundlage der Therapieempfehlung",
    somaticVariants: "Somatische Varianten (SNVs)",
    copyNumberVariants: "Kopienzahlveränderungen (CNVs)",
    structuralVariants: "Strukturvarianten",
    noVariantsFound: "Es wurden keine Varianten in dieser Kategorie gefunden.",
    reportInformation: "Befundinformationen",
    addTherapyRecommendationInfo: "Sie können über den Button ein oder meherere Therapieempfehlungen hinzufügen.",
    noProtocolsFound: "Es wurden keine Protokolle gefunden.",
    searchProtocol: "Protokoll suchen",
    signature: "Unterschrift | Unterschriften",
    evidenceLevels: "Evidenzklassen"
  },
  dateInput: {
    day: "Tag",
    month: "Monat",
    year: "Jahr",
    error: {
      title: "Fehler",
      notANumber: "Bitte eine Zahl eingeben",
      monthNeeded: "Bitte Monat angeben",
      yearNeeded: "Bitte Jahr angeben",
      dateNotValid: "Das Datum ist nicht valide"
    }
  },
  navbar: {
    imprint: "Impressum",
    profile: "Profil",
    signOut: "Abmelden"
  },
  chat: {
    chat: "Chat",
    writeMessage: "Nachricht schreiben...",
    connecting: "Verbinden...",
    inputHelp: "{enter} absenden, {shift}+{enter} neue Zeile",
    onTyping: "schreibt"
  },
  fhir: {
    assertedDate: "Feststellungsdatum",
    clinicalStatus: "Klinischer Status",
    issued: "Datum",
    status: "Status",
    category: "Kategorie",
    effectiveDateTime: "Datum",
    result: "Ergebnis",
    date: "Datum",
    gender: "Geschlecht",
    relationShip: "Beziehung",
    deceasedAge: "Alter zum Todeszeitpunkt",
    condition: "Diagnose",
    reasonCode: "Grund",
    taken: "eingenommen",
    code: "Code",
    outcome: "Ergebnis",
    reason: "Grund",
    title: "Titel",
    familyName: "Nachname",
    givenName: "Vorname",
    birthDate: "Geburtsdatum",
    male: "männlich",
    female: "weiblich",
    other: "andere",
    unknown: "unbekannt",
    diverse: "divers",
    generalPractitioner: "Behandelnder Arzt"
  },
  demo: {
    welcome: "Herzlich willkommen bei der Demo von VITU! Weitere Informationen zu den Funktionalitäten von VITU finden Sie unter {url}. Alle in der Demo gezeigten Daten werden täglich zurückgesetzt.",
    room: "Dies ist ein Demo-Raum. Um andere Teilnehmer in diesen Raum einzuladen, senden Sie bitte diesen Link: {url}"
  },
  roles: {
    roles: "Rollen",
    moderator: "Moderator",
    administrator: "Administrator",
    caseManager: "Fallmanager",
    freigeber: "Freigeber",
    participant: "Teilnehmer"
  },
  admin: {
    account: "Account",
    organization: "Organisation | Organisationen",
    addOrganization: "Organisation hinzufügen",
    editOrganization: "Organisation bearbeiten",
    existingOrganizations: "Vorhandene Orgainisationen",
    searchOrganization: "Suche Organisation",
    partOf: "",
    updatedOrganizationSuccessfully: "Die Änderungen wurden erfolgreich übernommen",
    updatedUserSuccessfully: "Die Änderungen wurden erfolgreich übernommen",
    Address: {
      line: "Zeile",
      postalCode: "Postleitzahl",
      state: "Bundesland",
      city: "Stadt",
      country: "Land"
    },
    description: "Beschreibung",
    user: "Benutzer",
    userList: "Benutzerliste",
    addUser: "Benutzer hinzufügen",
    usernameAlreadyPresent: "Der Nutzername '{username}' ist bereits vergeben.",
    emailAlreadyPresent: "Es existiert bereits ein Nutzer mit der E-Mail Adresse '{email}'.",
    deleteUser: "Benutzer löschen",
    deleteUserConfirmation: "Möchten Sie den Nutzer '{user}' wirklich löschen?",
    deleteUserSuccessful: "Der Benutzer wurde erfolgreich gelöscht.",
    createUserSuccessful: "Der Benutzer wurde erfolgreich angelegt.",
    editUserSuccessful: "Der Benutzer wurde erfolgreich gespeichert.",
    editUser: "Benutzer bearbeiten",
    adminArea: "Adminbereich",
    statusList: "Statusliste",
    addStatus: "Status hinzufügen",
    editStatus: "Status bearbeiten",
    statusAlreadyExists: "Es existiert bereits ein Status mit diesem Namen.",
    conferenceSettings: "Konferenzeinstellungen",
    bitrate: "Videoqualität",
    aspectRatio: "Format",
    save: "Speichern",
    saveSuccessful: "Speichern erfolgreich",
    statusListDescription: "Sie können die Status aus der Liste wählen und nicht verwendete Status deaktivieren. Mittels drag&drop können Sie die Status neu sortieren. Klicken Sie dann auf Speichern, um die Änderungen zu übernehmen.",
    statusListDescriptionHint: `Bitte beachten Sie, dass eine Änderung sich auf alle Tumorkonferenzen bezieht, bereits zugewiesene Status, 
    die nachträglich deaktiviert werden, werden in der Arbeitsliste nach Speichern der Änderung farblich rot hervorgehoben. Eine Statusänderung des Falls wird empfohlen.`,
    active: "Aktiv",
    inactive: "Inaktiv",
    showInactiveStatuses: "Inaktive Status einblenden",
    noInactiveStatusesFound: "Es wurden keine inaktiven Status gefunden.",
    persistentRoomEnabled: "Ad-hoc-Raum aktiv",
    persistentRoomName: "Ad-hoc-Raum Name",
    cutVideoStreams: "Videostreams zuschneiden",
    bitrates: {
      low: "Niedrig",
      medium: "Mittel",
      high: "Hoch",
      veryHigh: "Sehr hoch",
      unlimited: "Unbegrenzt"
    },
    showDateTimeInTitle: "Datum im Konferenztitel anzeigen",
    turnUrl: "STUN/TURN Url",
    turnUser: "STUN/TURN Nutzername",
    turnSecret: "STUN/TURN Passwort",
    noOrganizations: "Es konnten keine Organisationen gefunden werden.",
    createNotification: "Meldung erstellen",
    showNotification: "Meldung allen Benutzern anzeigen",
    deleteOrganization: "Organisation löschen",
    deleteOrganizationConfirmation: "Möchten Sie die Organisation '{organization}' wirklich löschen?",
    deleteOrganizationSuccessful: "Die Organisation wurde erfolgreich gelöscht."
  },
  error: {
    errorOccurred: "Es ist ein Fehler aufgetreten",
    warningOccurred: "Warnung",
    unauthorized: "Sie sind nicht berechtigt, diese Operation durchzuführen.",
    pageNotFound: "Seite nicht gefunden",
    pageNotFoundDescription: "Die angeforderte Seite konnte nicht gefunden werden. Bitte stellen Sie sicher, dass die Adresse korrekt ist.",
    noConnectionToChat: "Die Verbindung zum Chat konnte nicht hergestellt werden.",
    clinicalDataNotAvailable: "Klinische Daten können nur innerhalb des Kliniknetzwerkes abgerufen werden."
  },
  firstName: "Vorname",
  lastName: "Nachname",
  username: "Nutzername",
  password: "Passwort",
  passwordConfirmation: "Passwort bestätigen",
  passwordCriteria: "Das Passwort muss folgende Kriterien erfüllen: mindestens 8 Zeichen, 1 Großbuchstabe, 1 Kleinbuchstabe und 1 Zahl.",
  passwordsNotMatching: "Die Passwörter stimmen nicht überein.",
  email: "E-Mail",
  parentOrganization: "Übergeordnete Organisation",
  organizationInfo: "Die Organisation der diese Organisation untergeordnet ist",
  phone: "Telefon",
  country: "Land",
  city: "Stadt",
  postalCode: "Postleitzahl",
  name: "Name",
  contact: "Kontakt",
  address: "Adresse",
  street: "Straße",
  houseNumber: "Hausnummer",
  organization: "Organisation",
  noEntriesFound: "Keine Einträge gefunden",
  patientId: "Patienten-ID",
  clinicalCaseNumber: "Fallnummer",
  sex: "Geschlecht",
  male: "männlich",
  female: "weiblich",
  unknown: "unbekannt",
  other: "divers",
  pleaseSelect: "Bitte auswählen",
  cancel: "Abbrechen",
  cancelEditNote: "Wenn Sie die Bearbeitung beenden, werden alle nicht gespeicherten Änderungen verworfen. Möchten Sie wirklich die Bearbeitung beenden?",
  cancelEditYes: "Bearbeitung beenden",
  cancelCreateNote: "Wenn Sie die Erstellung beenden, werden alle nicht gespeicherten Änderungen verworfen. Möchten Sie wirklich die Erstellung beenden?",
  cancelCreateYes: "Erstellung beenden",
  notes: "Notizen",
  collapse: "Zuklappen",
  uncollapse: "Aufklappen",
  add: "Hinzufügen",
  hint: "Hinweis",
  search: "Suchen",
  noInformation: "keine Angabe",
  print: "Drucken",
  yes: "Ja",
  no: "Nein",
  clinicalCase: "Fall",
  addClinicalCase: "Fallanmeldung",
  editClinicalCase: "Fall bearbeiten",
  noAuditEvents: "Es sind keine Logs verfügbar",
  object: "Objekt",
  action: "Aktion",
  outcome: "Ergebnis",
  auditLog: "Audit Log",
  type: "Typ",
  subtype: "Subtyp",
  new: "Neu",
  addPatient: "Neuen Patient anmelden",
  patientIdInfo: "Die ID, die dem Patient im Krankenhausinformationssystem (KIS) zugewiesen ist.",
  caseIdInfo: "Die ID, die dem Fall im Krankenhausinformationssystem (KIS) zugewiesen ist.",
  icd10Info: "Bitte geben Sie den ICD10 Code der Diagnose ein.",
  setNewPassword: "Neues Passwort setzen",
  hintTemporaryPassword: "Dies ist ein temporäres Passwort. Beim nächsten Login muss der Nutzer ein neues Passwort vergeben.",
  creatingPatientFailed: "Erstellen des Patienten ist fehlgeschlagen",
  patientCreatedSuccessfully: "Der Patient wurde erfolgreich angelegt.",
  patientUpdatedSuccessfully: "Die Änderungen wurden erfolgreich übernommen",
  selectAnotherPatient: "Einen anderen Patienten auswählen",
  placeholderDate: "JJJJ-MM-TT",
  changePassword: "Passwort ändern",
  close: "Schließen",
  registerCase: "Fallanmeldung starten",
  courseOfDisease: "Bisheriger Krankheitsverlauf",
  inclusionCriteria: "Indikation für MTB",
  icd10: "ICD-10",
  icdo3: "ICD-O-3",
  uicc: "Initiales UICC Stadium",
  dischargeSummary: "Arztbrief",
  mostRecentDischargeSummary: "Aktueller Arztbrief",
  additionalDischargeSummary: "Weiterer Arztbrief",
  chooseFile: "Datei auswählen",
  therapyStartDate: "Beginn",
  therapyEndDate: "Ende",
  therapyType: "Therapieart",
  dateOfDiagnosis: "Datum der Erstdiagnose",
  browse: "Durchsuchen",
  dataDonation: "Datenspende",
  consentForDataDonation: "Einwilligung zur Datenspende vorhanden",
  diagnosticReport: "Befund | Befunde",
  pathologyReport: "Pathologiebefund",
  addTherapy: "Weitere Therapie hinzufügen",
  pleaseSelectPatient: "Bitte wählen Sie den Patient aus, den Sie anmelden möchten.",
  dateInfo: "Sie können nur Jahr, Jahr und Monat, oder Jahr, Monat und Tag eingeben.",
  medication: "Medikament",
  currentStatus: "Aktueller Erkrankungszustand",
  ecogScore: "ECOG Score",
  currentEcogScore: "Aktueller ECOG Score",
  consent: "Patienteneinverständnis",
  tumorBoard: "Tumorboard",
  discussionInterSite: "Besprechung im standortübergreifenden Tumorboard",
  tnm: "TNM",
  initialTnm: "Initialer TNM",
  copyToken: "Token kopieren",
  tokenSuccessfullyCopied: "Das Authentifizierungs-Token wurde erfolgreich in die Zwischenablage kopiert.",
  message: "Nachricht",
  period: "Zeitraum",
  performedDiagnostics: "Durchgeführte Diagnostiken",
  options: "Optionen",
  furtherInformation: "Weitere Informationen",
  comment: "Kommentar | Kommentare",
  relevantSecondaryDiagnoses: "Relevante Nebendiagnosen",
  instantOfTime: "Zeitpunkt",
  molecularTargetStructure: "Molekulare Zielstruktur",
  evidence: "Evidenz",
  evidenceLevel: "Evidenzlevel",
  treatmentContext: "Behandlungskontext",
  indicationInfo: "Mindestens eine Indikation auswählen, Mehrfachangaben sind möglich.",
  caseRegistrationInformation:
    "Die im Folgenden mit einem Stern (*) markierten Felder sind Pflichtangaben für die finale Fallanmeldung. Sie können die Anmeldung jedoch zwischenspeichern, ohne dass alle Pflichtfelder ausgefüllt sind. Bitte überprüfen Sie vor Anmeldung des Falls, ob lediglich die Daten angegeben wurden, die für die Fallbesprechung erforderlich sind.",
  addAdditionalDischargeSummary: "Weiteren Arztbrief hinzufügen",
  addAdditionalDocument: "Weiteres Dokument hinzufügen",
  addDocuments: "Dokumente hinzufügen",
  additionalDocument: "Weiteres Dokument",
  relevantPathologyReport: "Relevanter Pathologiebefund",
  additionalReport: "Weiterer Befund",
  addAdditionalReport: "Weiteren Befund hinzufügen",
  document: "Dokument | Dokumente",
  positive: "Positiv",
  negative: "Negativ",
  "microsatellite instability (msi)": "MSI",
  "microsatellite stable (mss)": "MSS",
  showVideo: "Video initial anzeigen",
  enableAudioInitially: "Audio initial aktivieren",
  recommendationCategory: "Rationale",
  participantList: "Teilnehmerliste",
  moderationPanel: "Moderationsbereich",
  reallyLeaveConference: "Möchten Sie die Konferenz wirklich verlassen?",
  pleaseConfirm: "Bitte bestätigen",
  openCaseOverview: "Fallübersicht öffnen",
  openDetailledCaseOverview: "Detaillierte Fallübersicht öffnen",
  caseOverviewDescription: "Über den Button können Sie die Fallübersicht öffnen. Diese öffnet sich in einem neuen Fenster.",
  caseList: "Fallliste",
  settings: "Einstellungen",
  microphone: "Mikrofon",
  fullscreen: "Vollbild",
  videoNewTab: "In neuem Tab öffnen",
  addAll: "Alle hinzufügen",
  deleteAll: "Alle löschen",
  participant: "Teilnehmer | Teilnehmer",
  daily: "Täglich",
  color: "Farbe",
  permanent: "Dauerhaft",
  showVideosInitially: "Videos initial anzeigen",
  dateAndTime: "Datum und Uhrzeit",
  addComment: "Kommentar verfassen",
  deleteComment: "Kommentar löschen",
  newestFirst: "Neueste zuerst",
  oldestFirst: "Älteste zuerst",
  caseOverview: "Fallübersicht",
  commentVerb: "Kommentieren",
  openCaseList: "Fallliste aufrufen",
  molecularDiagnostic: "Molekulargenetische Diagnostik",
  editPatientInformation: "Anmeldedaten bearbeiten",
  generateProtocol: "Empfehlungsprotokoll generieren",
  recommendationProtocol: "Empfehlungsprotokoll",
  uploadRecommendationProtocol: "Empfehlungsprotokoll hochladen",
  cannotDeleteYourself: "Sie können sich nicht selbst löschen.",
  userFromFederationInfo: "Einige Felder können nicht bearbeitet werden, da der Nutzer aus einer Domäne (LDAP/Active Directory) stammt.",
  existingUsers: "Vorhandene Nutzer",
  selectAll: "Alle auswählen",
  finalizeCaseRegistration: "Fallanmeldung abschließen",
  "not tested": "Nicht getestet",
  subType: "Subtyp",
  noComments: "Es sind noch keine Kommentare vorhanden.",
  copyEmails: "Alle E-Mail-Adressen kopieren",
  emailsSuccessfullyCopied: "Alle E-Mail-Adressen wurden erfolgreich in die Zwischenablage kopiert.",
  noMoreVideoStreamsAllowed: "Keine weiteren Videostreams zugelassen",
  maxNumberOfVideos: "Max. Anzahl Videostreams",
  indicationWarning: "Bitte wählen Sie mindestens eine Indikation aus.",
  copyUsers: "Namen kopieren",
  usersCopiedSuccessfully: "Nutzerdaten wurden erfolgreich kopiert.",
  upload: "Dokumentenupload",
  addCaseInformation: "Fallinformationen hinzufügen",
  chooseFiles: "Datei(en) auswählen",
  file: "Datei | Dateien",
  uploadSuccessful: "Die Datei(en) wurde(n) erfolgreich hochgeladen.",
  uploadHint: "Bitte hinterlassen Sie eine kurze Beschreibung des Falls im Kommentarfeld und geben Sie an, von welchem Klinikum der Patient angemeldet wird. Diesen Kommentar sehen ausschließlich die Nutzer mit der Rolle Moderator.",
  infoPage: "Informationsseite",
  detailedInformation: "Detailinformationen",
  addEntry: "Eintrag hinzufügen",
  study: "Studie | Studien",
  uploadCaseInfo: "Fallinformationen importieren",
  participantOnly: "Nur als Zuhörer beitreten",
  dialIn: "Einwählen",
  newVersion: "Neue Version verfügbar!",
  refresh: "Aktualisieren",
  patientConsent: {
    consentTitle: "Einwilligung in die Nutzung von Patientendaten für medizinische Forschungszwecke",
    datenspende: "Erhebung, Verarbeitung und wissenschaftliche Nutzung meiner Patientendaten, wie in der Patienteninformation beschrieben",
    kontaktaufnahme_Zusatzbefunde: " Möglichkeit einer erneuten Kontaktaufnahme (medizinische Zusatzbefunde) ",
    kontaktaufnahme_wissenschaftlich: " Möglichkeit einer erneuten Kontaktaufnahme (wissenschaftliche Fragen) ",
    datenspendeDetail:
      "Dies umfasst </br> 1.) die Verarbeitung und Nutzung meiner Patientendaten für die medizinische Forschung Verwaltung des Namens und anderer direkt identifizierender Daten (Codierung) wie in der Patienteninformation beschrieben (siehe Punkte 1.1 und 1.2). </br> 2.) die wissenschaftliche Analyse und Nutzung meiner codierten Patientendaten durch Dritte wie z.B. durch andere Kliniken, Universitäten, Institute, forschende Unternehmen; dies kann auch eine Weitergabe für Forschungsprojekte im Ausland umfassen, wenn in diesen europäisches Datenschutzrecht gilt oder die Europäische Kommission ein angemessenes Datenschutzniveau bestätigt hat. An einem etwaigen kommerziellen Nutzen aus der Forschung werde ich nicht beteiligt. Vor einer Weitergabe an Forscher außerhalb meiner behandelnden Einrichtung erfolgt zudem eine weitere Ersetzung des internen Kennzeichens durch eine neue Zeichenkombination. </br> 3.) die Möglichkeit einer Zusammenführung meiner Patientendaten mit Daten in Datenbanken anderer Forschungspartner. Voraussetzung ist, dass ich dieser Nutzung bei den entsprechenden Forschungspartnern auch zugestimmt habe. </br> Ich willige ein in die Erhebung, Verarbeitung, Speicherung und wissenschaftliche Nutzung meiner Patientendaten wie in Punkt (1) bis (3) der Einwilligungserklärung und Punkt 1 der Patienteninformation beschrieben.",
    kontaktaufnahme_Zusatzbefunde_Detail: "Ich willige ein, dass ich wieder kontaktiert werden darf, um über medizinische Zusatzbefunde informiert zu werden (siehe Punkt 2.2 der Patienteninformation).",
    kontaktaufnahme_wissenschaftlich_Detail:
      "Ich willige ein, dass ich erneut kontaktiert werden darf, um gegebenenfalls zusätzliche für wissenschaftliche Fragen relevante Informationen zur Verfügung zu stellen, um über neue Forschungsvorhaben/Studien informiert zu werden, und/oder um meine Einwilligung in die Verknüpfung meiner Patientendaten mit medizinischen Informationen aus anderen Datenbanken einzuholen (siehe Punkt 2.1 der Patienteninformation).",
    showMore: "mehr anzeigen",
    showLess: "weniger anzeigen",
    noInfo: "Es liegen keine Informationen zur Einwilligung vor.",
    consentAvailable: "Einwilligung in die Nutzung von Patientendaten für medizinische Forschungszwecke wurde erteilt",
    support:
      'Mit dem Klick auf "Datenspende öffnen" wird das Datenspendemodul geöffnet. Dort müssen die vom Patienten ausgewählten Einwilligungspunkte hinterlegt werden. Mit dem Klick auf "Datenspende bestätigen" wird die Einwilligung digital übermittelt. Nach dem Speichern Klicken Sie bitte auf "OK", damit werden Sie auf die Patientenseite zurückgeleitet. Bitte warten Sie, bis der Prozess abgeschlossen ist, damit alle Daten ordnungsgemäß übertragen werden können. {br}{br} Sollten bei der Übertragung von Datenspende-Informationen technische Probleme aufgetreten oder versehentlich falsche Angaben übermittelt worden sein, wenden Sie sich bitte über support@molit.eu an das MOLIT Institut.',
    consentPresent: "Einwilligung Datenspende vorhanden"
  },
  patientAddress: "Adresse des Patienten",
  masterData: "Stammdaten",
  reallyDelete: "Möchten Sie diese Resource wirklich löschen?",
  deleteConference: "Konferenz löschen",
  reallyDeleteConference: "Möchten Sie diese Konferenz wirklich löschen?",
  conferenceDeleteSuccessful: "Die Konferenz wurde erfolgreich gelöscht",
  import: "Importieren",
  importMolecularReport: "Molekulardiagnostischen Befund importieren",
  deleteReport: "Befund löschen",
  reallyDeleteReport: "Möchten Sie diesen Befund wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden!",
  editDiagnosis: "Diagnosedaten bearbeiten",
  editIndication: "Indikation bearbeiten",
  editDiagnostics: "Diagnostiken bearbieten",
  editFurtherInformation: "Weitere Informationen bearbeiten",
  addReason: "Grund hinzufügen",
  prio: "Prio",
  activeSubstance: "Wirkstoff",
  export: "Exportieren",
  externalCaseId: "Externe Fallnummer",
  serviceRequest: "Befundanforderung",
  showOnlyDataDonation: "Nur Datenspendepatienten anzeigen",
  value: "Wert",
  edit: "Bearbeiten",
  ingredient: "Wirkstoff",
  noMatchingOptions: "Keine passenden Optionen",
  otherReason: "Sonstiger Grund",
  noPermissionsTitle: "Keine Berechtigung",
  noPermissionsDscrp: "Diesem Account sind keine Berechtigungen zugewiesen. Bitte kontaktieren Sie den Administrator.",
  validationMsg: {
    empty: "Bitte füllen Sie dieses Feld aus",
    postalCode: "Die Eingabe muss mit dem geforderten Format übereinstimmen",
    gender: "Bitte Geschlecht auswählen",
    birthDate: "Bitte Geburtsdatum auswählen",
    email: "Bitte geben Sie eine Email-Adresse mit gültigem Format ein",
    password: "Bitte geben Sie ein Passwort ein, welches mit dem geforderten Format übereinstimmt"
  },
  ownCases: "Eigene Fälle",
  preview: "Vorschau",
  continueEditing: "Weiter bearbeiten",
  formatting: "Formatierungshinweise",
  markdownTutorial: `
# Grundlegende Schreib- und Formatierungssyntax
Im Folgenden finden Sie eine Auswahl mit kurzen Beschreibungen, wie Sie unter Verwendung bestimmter Zeichenfolgen auf die Darstellung Ihrer Texte Einfluss nehmen können. 

## Überschriften
Um eine Überschrift zu erstellen, können Sie bis zu sechs Rautensymbole (#) vor dem Text der Überschrift hinzufügen. Die Anzahl der verwendeten Rauten \`#\` bestimmt die Hierarchieebene und die Schriftgröße der Überschrift.

\`\`\`
# First-level Überschrift
## Second-level Überschrift
### Third-level Überschrift
\`\`\`

# First-level Überschrift
## Second-level Überschrift
### Third-level Überschrift

---

## Formatieren von Text
Die folgende Tabelle enthält eine Auswahl an unterstützten Formatierungsmöglichkeiten.

| Style  | Syntax | Beispiel | Output|
| ------ | ------ |--------- |------ |
| Fett | \`** **\` | \`**Dieser Text ist fett**\` | **Dieser Text ist fett.** |
| Kursiv | \`* *\` | \`*Dieser Text ist kursiv*\` | *Dieser Text ist kursiv.* |
| Durchgestrichen | \`~~ ~~\` | \`~~Dieser Text war falsch.~~\` | ~~Dieser Text war falsch.~~ |
| Fett und kursiv verschachtelt | \`** **\` und \`_ _\` | \`**Dieser Text ist _sehr_ wichtig.**\` | **Dieser Text ist _sehr_ wichtig.** |
| Alles fett und kursiv  | \`*** ***\` | \`***Dieser gesamte Text ist wichtig.***\`  | ***Dieser gesamte Text ist wichtig.***  |
| Link  | \`[](url)\` | \`[MOLIT Service](https://molit-service.de/)\`  | [MOLIT Service](https://molit-service.de/)  |
| Zeilenumbruch | \`  \` | Zwei Leerzeichen am Zeilenende  | Erste Zeile<br>Zweite Zeile |

---
		
## Listen
Sie können eine ungeordnete Liste erstellen, indem Sie einer Textzeile oder mehreren Textzeilen \`-\`, \`*\` oder \`+\` voranstellen.

\`\`\`
Wir haben folgende Studien zu diesem Fall identifiziert:
- Studie A
- Studie B
- Studie C
\`\`\`
Wir haben folgende Studien zu diesem Fall identifiziert:
- Studie A
- Studie B
- Studie C

---

## Nummerierte Listen

Sie können eine nummerierte Liste erstellen, indem Sie einer Textzeile oder mehreren Textzeilen \`1.\` voranstellen.

\`\`\`
Wir haben folgende Studien zu diesem Fall identifiziert:
1. Studie A
1. Studie B
1. Studie C
\`\`\`
Wir haben folgende Studien zu diesem Fall identifiziert:
1. Studie A
1. Studie B
1. Studie C
  `
};

export default de;
