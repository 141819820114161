<template>
  <div class="documentation-overview-wrapper">
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      :successMessage="success"
      @closeSuccess="closeSuccess"
      @closeWarning="closeWarning"
      @closeError="closeError"
    />
    <div class="documentation-overview">
      <div class="container">
        <div class="page-header">
          <h4 class="headline">{{ $t("documentation.documentation") }}</h4>
        </div>
        <div class="main">
          <h5>{{ $t("documentation.existingProtocols") }}</h5>
        </div>
        <div class="mt-4">
          <div v-if="!loading">
            <div v-for="file in files" :key="file.id">
              <a class="" @click="openDocument(file)" href="javascript:void(null);">{{ file.description }}</a>
            </div>
          </div>
          <spinner v-else size="small" :speed="1.5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPanels from "@/components/ui/NotificationPanels";
import config from "@/config/config";
import notifications from "@/mixins/notifications";
import { mapState } from "vuex";

import Spinner from "vue-simple-spinner";
import { fetchResources, mapFhirResponse } from "@molit/fhir-api";

import { authenticatedDownload } from "@/util/util";
import { get } from "lodash";
import moment from "moment";
import roles from "@/model/roles";

export default {
  mixins: [notifications],

  data() {
    return {
      error: null,
      showSuccess: false,
      protocols: null,
      params: {
        _summary: false,
        _count: 10000,
        _sort: "-date",
        date: "gt" + moment().subtract(20, "d").format("YYYY-MM-DD"),
        type: "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl|C25197",
        "subject:Patient.organization.name": "xxxxxx"
      },
      loading: false,
      files: null
    };
  },

  computed: {
    fhirBaseUrl() {
      return config.FHIR_URL;
    },

    fhirBaseUrlExternal() {
      return config.FHIR_URL_EXTERNAL;
    },

    ...mapState({
      token: state => state.authentication.keycloak.token,
      userOrganisation: state => state.authentication.keycloak.idTokenParsed.company,
      keycloak: state => state.authentication.keycloak
    }),

    isModerator() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.MODERATOR);
      } else {
        return false;
      }
    },

    isAdmin() {
      if (this.keycloak) {
        return this.keycloak.hasRealmRole(roles.ADMINISTRATOR);
      } else {
        return false;
      }
    }
  },

  methods: {
    async getFiles() {
      this.files = mapFhirResponse(await fetchResources(this.fhirBaseUrlExternal, "DocumentReference", this.params, this.token));
    },

    openDocument(documentReference) {
      const data = get(documentReference, "content[0].attachment.data");
      let location = get(documentReference, "content[0].attachment.url");
      if (data) {
        const linkSource = `data:application/pdf;base64,${data}`;
        const downloadLink = document.createElement("a");
        const fileName = documentReference.description + ".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } else if (location != null) {
        location = location.replace(this.fhirBaseUrl, this.fhirBaseUrlExternal);
        console.log(location);
        const title = get(documentReference, "content[0].attachment.title");
        authenticatedDownload(location, title, this.token);
      }
    }
  },

  async mounted() {
    try {
      this.loading = true;
      if (this.isAdmin || this.isModerator) {
        delete this.params["subject:Patient.organization.name"];
      } else if (this.userOrganisation != null && this.userOrganisation != "") {
        this.params["subject:Patient.organization.name"] = this.userOrganisation;
      }
      await this.getFiles();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },

  components: {
    NotificationPanels,
    Spinner
  }
};
</script>

<style lang="scss" scoped>
.documentation-overview {
  padding-bottom: 1rem;
}

.main {
  padding-top: 1rem;
}

.documentation-overview-wrapper {
  background: white;
  flex: 1;
}

.list-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0.8rem;
  padding-bottom: 0.6rem;

  &:last-child {
    border: 0;
  }

  cursor: pointer;
}

.icon {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.5rem;
}
</style>
