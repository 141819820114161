<template>
  <div class="background pt-2">
    <notification-panels
      :showError="showError"
      :errorMessage="error"
      :showWarning="showWarning"
      :warning="warning"
      :showSuccess="showSuccess"
      :successMessage="success"
      @closeSuccess="closeSuccess"
      @closeWarning="closeWarning"
      @closeError="closeError"
    />
    <spinner v-if="loading && !error" />
    <div v-if="!loading && !error">
      <div style="padding-right: 260px">
        <div>
          <div class="case-overview" v-if="selectedEpisodeOfCare">
            <case-overview :episodeOfCareId="selectedEpisodeOfCare"></case-overview>
          </div>
          <div v-else class="container">
            <div class="page-header">
              <h5 class="headline">{{ $t("planner.conferenceModerator") }}</h5>
              <div class="spacer"></div>
            </div>
            {{ $t("planner.selectACase") }}
          </div>
        </div>
      </div>
      <div class="case-list">
        <div class="list-header">
          <h5>{{ $t("worklist.cases") }}</h5>
        </div>
        <div v-if="cases && cases.length">
          <div
            class="list-item"
            v-for="(entry, index) in cases"
            :key="entry.id"
            :class="{ rowSelected: selectedEpisodeOfCare === entry.episodeOfCare[0].reference.split('/')[1], notSelected: selectedEpisodeOfCare !== entry.episodeOfCare[0].reference.split('/')[1] }"
            @click="selectedEpisodeOfCare = entry.episodeOfCare[0].reference.split('/')[1]"
          >
            <div class="list-icon-placement">
              <clipboard-icon class="list-item-icon"></clipboard-icon>
            </div>
            <div>
              <div class="list-item-title">
                {{ index + 1 }}/{{ cases.length }}
                {{ getName(entry.subject.display) }}
              </div>
              <div>{{ getBirthDate(entry.subject.display) }}</div>
              <div>{{ entry.subject.managingOrganization }}</div>
            </div>
          </div>
        </div>
        <div class="pt-2 pl-2" v-else>
          {{ $t("planner.noCases") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPanels from "@/components/ui/NotificationPanels";
import notifications from "@/mixins/notifications";
import ClipboardIcon from "vue-material-design-icons/ClipboardText";
import CaseOverview from "@/components/CaseOverview";
import { getRoomsAccessible } from "@/api/video-api";
import Spinner from "@/components/ui/Spinner";
import { mapState } from "vuex";
import { fetchCasesByRoomId, fetchResourcesByIds } from "@/api/worklist-api";
import config from "@/config/config";

export default {
  mixins: [notifications],

  data() {
    return {
      loading: true,
      selectedEpisodeOfCare: null,
      roomId: null,
      roomName: null,
      roomDate: null,
      cases: []
    };
  },

  computed: {
    ...mapState({
      token: state => state.authentication.keycloak.token
    }),

    fhirBaseUrl() {
      return config.FHIR_URL;
    }
  },

  methods: {
    async loadVideoConference(id) {
      const roomId = parseInt(id);
      const rooms = (await getRoomsAccessible(this.token, 10000)).data.entity;
      const currentRoom = rooms.find(room => room.janusId === roomId);
      if (currentRoom && currentRoom.tumorConference) {
        this.roomId = currentRoom.id;
        this.roomName = currentRoom.tumorConference.description;
        this.roomDate = currentRoom.tumorConference.date;
      }
    },

    async fetchTmbEncounter() {
      this.cases = await fetchCasesByRoomId(this.fhirBaseUrl, this.token, this.roomId);
    },

    async fetchPatients() {
      try {
        const patients = (
          await fetchResourcesByIds(
            this.fhirBaseUrl,
            this.cases.map(clinicalCase => clinicalCase.subject.reference),
            "Patient",
            this.token
          )
        ).data.entry.map(e => e.resource);
        for (const clinicalCase of this.cases) {
          const patient = patients.find(p => p.id === clinicalCase.subject.reference.split("/")[1]);
          clinicalCase.subject.managingOrganization = patient.managingOrganization.display;
        }
      } catch (e) {
        console.error(e);
      }
    },

    getName(display) {
      if (display != null) {
        return display.split(",")[0];
      }
      return "";
    },

    getBirthDate(display) {
      if (display != null) {
        return display.split(",")[1];
      }
      return "";
    }
  },

  async created() {
    if (this.$route.params.room) {
      try {
        await this.loadVideoConference(this.$route.params.room);
        await this.fetchTmbEncounter();
        await this.fetchPatients();
        this.loading = false;
      } catch (e) {
        this.handleError(e);
        this.loading = false;
      }
    } else {
      this.error = "No conference found.";
      this.loading = false;
    }
  },

  components: {
    Spinner,
    NotificationPanels,
    ClipboardIcon,
    CaseOverview
  }
};
</script>

<style lang="scss" scoped>
.case-overview {
  padding-top: 15px;
}

.rowSelected {
  border-left: 4px solid $vitu-green;

  .list-item-icon {
    color: $vitu-green;
  }
}

.notSelected {
  padding-left: 4px !important;
}

.list-header {
  font-weight: 500;
  padding: 15px;
  border-bottom: 1px solid $border-color;
}

.list-item {
  padding: 10px 0;
  display: flex;
  border-bottom: 1px solid $border-color;
  cursor: pointer;
}

.list-item-title {
  font-weight: 500;
}

.list-icon-placement {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}

.list-item-icon {
  font-size: 30px;
  color: $vitu-grey;
}

.background {
  background-color: white;
  min-height: calc(100vh - 66px);
}

.case-list {
  position: fixed;
  height: calc(100vh - 60px);
  overflow-x: auto;
  right: 0;
  top: $navbar-height;
  bottom: 0;
  background: white;
  width: 260px;
  border-left: 1px solid $border-color;
}
</style>
